import { AlertColor } from "@mui/material/Alert/Alert"
import * as React from "react"
import { PropsWithChildren, useState } from "react"
import { createContainer } from "unstated-next"
import { CommonDialogProps } from "@/components/common/CommonDialog"

export type MessageState = {
  icon?: React.ReactNode
  severity?: AlertColor
  message?: string
  display?: boolean
}

function useAlert() {
  const [state, setState] = useState<MessageState>({
    severity: "error",
    message: "",
    display: false,
  })

  const [dialogState, setDialogState] = useState<CommonDialogProps>({
    open: false,
  })

  const openDialog = (
    props: PropsWithChildren<Omit<CommonDialogProps, "open">>
  ) => setDialogState({ open: true, close: closeDialog, ...props })

  const closeDialog = () => setDialogState({ open: false })

  const pushAlert = (newState: MessageState) => {
    setState({ ...state, ...newState, display: true })
  }

  const closeAlert = () =>
    setState(preState => ({ ...preState, display: false }))

  return {
    state,
    pushAlert,
    dialogState,
    closeDialog,
    openDialog,
    closeAlert,
  }
}

const AlertContainer = createContainer(useAlert)

export default AlertContainer
