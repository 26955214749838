import { createTheme } from "@mui/material"

export const materialTheme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      50: "#EEF6FA",
      main: "#376AB3",
      dark: "#00559f",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#3F90C2",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#d40022",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#17a025",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#333333",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "rgba(63, 63, 63, 0.6)",
      disabled: "rgba(63, 63, 63, 0.38)",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
      formControlBg: "#f5f8fa",
    },
    action: {
      active: "#999999",
      outline: "#DCDDDF",
      hover: "rgba(67, 131, 228, 0.2)",
      selected: "rgba(0, 0, 0, 0.08)",
      focus: "rgba(67, 131, 228, 0.3)",
    },
    grey: {},
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
})
