import { AlertMessage } from "@/components/common/AlertMessage"
import { diverChain } from "@/core/constant/chain"
import { materialTheme } from "@/theme/materialTheme"
import { ThemeProvider } from "@mui/material"
import { polygon, localhost, mainnet, bsc } from "@wagmi/chains"
import React, { PropsWithChildren, useEffect } from "react"
import { configureChains, createConfig, WagmiConfig } from "wagmi"
import { publicProvider } from "wagmi/providers/public"
import "@/core/plugins/i18next"
import AlertContainer from "@/core/store/AlertContainer"
import { AlertModal } from "../AlertModal"
import { InjectedConnector } from "wagmi/connectors/injected"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { useSearchParams } from "react-router-dom"

export const Layout: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [searchParams] = useSearchParams()
  const isMobileDiverWallet = searchParams.get("is_diver_wallet") === "true"
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [diverChain, mainnet, polygon, localhost, bsc],
    [publicProvider()]
  )
  const testModel = sessionStorage.getItem("metamask_connect")
  useEffect(() => scrollTo(0, 0), [location.pathname])

  useEffect(() => {
    if (isMobileDiverWallet && window.ethereum) {
      window.ethereum.isDiverWallet = true
      localStorage.setItem("is_diver_wallet", "true")
    }
  }, [isMobileDiverWallet])

  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: () => {
      const connectors = [
        new InjectedConnector({
          chains,
          options: {
            name: "DiverWallet",
            getProvider: () =>
              window.ethereum?.isDiverWallet || isMobileDiverWallet
                ? window.ethereum
                : undefined,
            shimDisconnect: true,
          },
        }),
      ]
      if (testModel === "true") {
        connectors.push(
          new MetaMaskConnector({
            chains,
            options: {
              shimDisconnect: true,
            },
          })
        )
      }
      return connectors
    },
  })

  return (
    <ThemeProvider theme={materialTheme}>
      <WagmiConfig config={config}>
        <AlertContainer.Provider>
          <main>{children}</main>
          <AlertModal />
          <AlertMessage />
        </AlertContainer.Provider>
      </WagmiConfig>
    </ThemeProvider>
  )
}
