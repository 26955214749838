export function getBrowserLang(lang?: string): string {
  switch (lang) {
    case "en":
    case "en_US":
    case "en-US":
      return "en"
    case "ja":
    case "ja_JP":
    default:
      return "ja"
  }
}
