import { Dialog, SxProps } from "@mui/material"
import React, { ReactNode } from "react"
import { DialogTypeEnum } from "@/core/enum/DialogTypeEnum"
import { AlertComponent } from "./AlertComponent"

export type CommonDialogProps = {
  children?: ReactNode
  type?: DialogTypeEnum
  open: boolean
  close?: () => void
  title?: string | JSX.Element | null
  content?: string | JSX.Element | null
  formLoading?: boolean
  buttonLeft?: {
    text: string
    action?: () => void
    loading?: boolean
    disabled?: boolean
  }
  buttonRight?: {
    text: string
    action?: ([key]?: any) => void
    loading?: boolean
    disabled?: boolean
  }
  sx?: SxProps
}

export const CommonDialog: React.FunctionComponent<
  CommonDialogProps
> = props => {
  const renderComponent = () => {
    switch (props.type) {
      case DialogTypeEnum.CONFIRM:
      case DialogTypeEnum.DELETE:
        return <AlertComponent {...props} />
      default:
        return <>{props.children}</>
    }
  }
  return (
    <Dialog
      onClose={props.close}
      open={props.open}
      sx={{
        "& .MuiPaper-root": { borderRadius: 4, maxWidth: "100vw", margin: 4 },
        "& .MuiBackdrop-root": { backgroundColor: "#505050a8" },
        ...(props.sx || {}),
      }}
    >
      {renderComponent()}
    </Dialog>
  )
}
