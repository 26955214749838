import { useRoutes, Navigate } from "react-router-dom"
import React, { ReactElement, Suspense } from "react"

const MainLayout = React.lazy(() =>
  import("../components/common/layout/MainLayout").then(({ MainLayout }) => ({
    default: MainLayout,
  }))
)

const IndexPage = React.lazy(() =>
  import("../pages").then(({ IndexPage }) => ({
    default: IndexPage,
  }))
)

const AccountPage = React.lazy(() =>
  import("../pages/account").then(({ AccountPage }) => ({
    default: AccountPage,
  }))
)

const CollectionPage = React.lazy(() =>
  import("../pages/collection").then(({ CollectionPage }) => ({
    default: CollectionPage,
  }))
)

const SequentialCollectionPage = React.lazy(() =>
  import("../pages/sequential_collection").then(
    ({ SequentialCollectionPage }) => ({
      default: SequentialCollectionPage,
    })
  )
)

const NFTDetailPage = React.lazy(() =>
  import("../pages/nft").then(({ NFTDetailPage }) => ({
    default: NFTDetailPage,
  }))
)

const CreateNFTPage = React.lazy(() =>
  import("../pages/create").then(({ CreateNFTPage }) => ({
    default: CreateNFTPage,
  }))
)

const CreateSequentialNFTPage = React.lazy(() =>
  import("../pages/create_sequential").then(({ CreateSequentialNFTPage }) => ({
    default: CreateSequentialNFTPage,
  }))
)

const CreateCollectionPage = React.lazy(() =>
  import("../pages/create_collection").then(({ CreateCollectionPage }) => ({
    default: CreateCollectionPage,
  }))
)

const CreateSequentialCollectionPage = React.lazy(() =>
  import("../pages/create_sequential_collection").then(
    ({ CreateSequentialCollectionPage }) => ({
      default: CreateSequentialCollectionPage,
    })
  )
)

const PrivacyPolicyPage = React.lazy(() =>
  import("../pages/privacy_policy").then(({ PrivacyPolicyPage }) => ({
    default: PrivacyPolicyPage,
  }))
)

const TermsofServicePage = React.lazy(() =>
  import("../pages/terms_of_service").then(({ TermsofServicePage }) => ({
    default: TermsofServicePage,
  }))
)

const NotFoundPage = React.lazy(() =>
  import("../pages/404").then(({ NotFoundPage }) => ({
    default: NotFoundPage,
  }))
)

export const RouterMap = (): ReactElement | null =>
  useRoutes([
    {
      path: "/",
      element: (
        <Suspense>
          <IndexPage />
        </Suspense>
      ),
    },
    {
      path: "/privacy_policy",
      element: (
        <Suspense>
          <PrivacyPolicyPage />
        </Suspense>
      ),
    },
    {
      path: "/terms_of_service",
      element: (
        <Suspense>
          <TermsofServicePage />
        </Suspense>
      ),
    },
    {
      element: (
        <Suspense>
          <MainLayout />
        </Suspense>
      ),
      children: [
        {
          path: "/account",
          element: (
            <Suspense>
              <AccountPage />
            </Suspense>
          ),
        },
        {
          path: "/create_collection",
          element: (
            <Suspense>
              <CreateCollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/create_sequential_collection",
          element: (
            <Suspense>
              <CreateSequentialCollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/create_nft",
          element: (
            <Suspense>
              <CreateNFTPage />
            </Suspense>
          ),
        },
        {
          path: "/create_sequential_nft",
          element: (
            <Suspense>
              <CreateSequentialNFTPage />
            </Suspense>
          ),
        },
        {
          path: "/nft/:address/:tokenId",
          element: (
            <Suspense>
              <NFTDetailPage />
            </Suspense>
          ),
        },
        {
          path: "/collection/:collectionId",
          element: (
            <Suspense>
              <CollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/sequential_collection/:collectionId",
          element: (
            <Suspense>
              <SequentialCollectionPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/404",
      element: (
        <Suspense>
          <NotFoundPage />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ])
