import React from "react"
import { useTranslation } from "react-i18next"
import { DialogTypeEnum } from "@/core/enum/DialogTypeEnum"
import { CommonButton } from "./CommonButton"
import { CommonDialogProps } from "./CommonDialog"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"

export const AlertComponent: React.FunctionComponent<CommonDialogProps> = ({
  title,
  close = () => undefined,
  type,
  content,
  buttonLeft,
  buttonRight,
}) => {
  const { t } = useTranslation()

  return (
    <div className="w-[420px] p-5 relative">
      <div className="text-lg font-bold mb-5 text-center">{title}</div>
      <div className="text-body2 px-10">{content}</div>
      <HighlightOffIcon
        className="absolute top-4 right-3 cursor-pointer"
        onClick={close}
      />
      <div className="flex justify-between px-4 mt-7">
        {buttonLeft && (
          <CommonButton
            className="w-1/2"
            variant="outlined"
            onClick={() => {
              buttonLeft.action ? buttonLeft.action() : close()
            }}
            sx={{
              marginRight: 2,
              minWidth: "auto",
              padding: "10px 20px",
              borderRadius: 0,
            }}
          >
            {buttonLeft.text || t("str_cancel")}
          </CommonButton>
        )}
        {buttonRight && (
          <CommonButton
            className="w-1/2"
            color={type === DialogTypeEnum.DELETE ? "error" : "primary"}
            variant="contained"
            onClick={() => {
              buttonRight.action ? buttonRight.action() : close()
            }}
            sx={{
              marginLeft: 2,
              minWidth: "auto",
              padding: "10px 20px",
              borderRadius: 0,
            }}
          >
            {buttonRight.text || t("str_confirm")}
          </CommonButton>
        )}
      </div>
    </div>
  )
}
